
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { isWechat } from '@/utils/utils'
import { formatDate } from '@/utils/date'
import { ElForm } from 'element-ui/types/form'

@Component({
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	public $refs!: {
		'dock-form': ElForm
	}
	private isWechat = false
	private searchForm = {
		outboundCode: '',
		date: [] as any[]
	}
	private pageConfig = {
		total: 0,
		size: 20,
		current_page: 1
	}
	private now = Math.floor(Date.now() / 1000)
	private expressCompany = []
	private dockDialog = {
		visible: false,
		form: {
			code: '',
			stockout_company: '',
			stockout_num: '',
			outbound_time: ''
		}
	}
	private validateDockInfo = {
		stockout_company: [
			{ required: true, message: '请选择', trigger: 'change' }
		],
		stockout_num: [
			{ required: true, message: '请输入出仓单号', trigger: 'blur' }
		],
		outbound_time: [
			{ required: true, message: '请选择出仓时间', trigger: 'change' }
		]
	}
	@Watch('pageConfig.current_page')
	onPagination() {
		this.search()
	}
	private isLoading = false

	private pickerOptions = {
		shortcuts: [
			{
				text: '最近一周',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
					picker.$emit('pick', [start, end])
				}
			},
			{
				text: '最近一个月',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
					picker.$emit('pick', [start, end])
				}
			},
			{
				text: '最近三个月',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
					picker.$emit('pick', [start, end])
				}
			}
		]
	}
	private list: any = []
	created() {
		this.isWechat = isWechat()
		const {
			outbound_code = '',
			size,
			current_page,
			start_time,
			end_time
		}: any = this.$route.query
		this.searchForm.outboundCode = outbound_code
		this.pageConfig.size = Number(size || 20)
		if (start_time && Number(start_time)) {
			this.searchForm.date = [
				formatDate('yyyy-mm-dd h:i:s ', start_time),
				formatDate('yyyy-mm-dd h:i:s ', end_time)
			]
		}
		this.pageConfig.current_page = Number(current_page || 1)
		this.search(true)
		this.getExpressCompany()
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}
	async scanBarcode() {
		if (!this.$wx) return false
		await this.initJSSdk()
		this.$wx.ready(() => {
			this.$wx.scanQRCode({
				needResult: 1,
				scanType: ['barCode'],
				success: (res: any) => {
					const result = res.resultStr.split(',')
					this.searchForm.outboundCode = result[result.length - 1]
					this.search()
				}
			})
		})
	}
	async editItem(code: string) {
		location.href = `/warehouse/stock-out/pack?boxcode=${code}`
	}
	async printLabel(code: string) {
		const baseUrl = process.env.VUE_APP_API_BASE_URL
		return `${baseUrl}v1/jobline/generate/package-bar-code/${code}`
	}
	resetSearch() {
		this.searchForm.outboundCode = ''
		this.searchForm.date = []
		this.search(true)
	}
	async search(reset?: boolean) {
		if (reset) {
			this.pageConfig.current_page = 1
		}
		const { outboundCode, date = [] } = this.searchForm
		const [start, end] = date || []
		const startTime = start
			? new Date(new Date(start).setHours(0, 0, 0, 0)).getTime() / 1000
			: ''
		const endTime = end
			? new Date(new Date(end).setHours(23, 59, 59, 59)).getTime() / 1000
			: ''
		const startDate = startTime
			? formatDate('yyyy-mm-dd h:i:s ', startTime).trim()
			: ''
		const endDate = endTime
			? formatDate('yyyy-mm-dd h:i:s ', endTime).trim()
			: ''
		const { size, current_page } = this.pageConfig

		const startDateUnixTime = startDate
			? Math.round(new Date(startDate).getTime() / 1000)
			: 0
		const endDateUnixTime = endDate
			? Math.round(new Date(endDate).getTime() / 1000)
			: 0
		const { data } = await this.$axios
			.get('/v1/jobline/warehouse-record', {
				params: {
					warehouse_record_code: outboundCode,
					start_time: startDateUnixTime,
					end_time: endDateUnixTime,
					page_size: size,
					page_index: current_page
				}
			})
			.catch(e => {
				this.$message.error(e.message)
				return { data: null }
			})

		this.$router
			.replace({
				query: {
					outbound_code: outboundCode,
					size: String(size),
					start_time: String(startDateUnixTime),
					end_time: String(endDateUnixTime),
					current_page: String(current_page)
				}
			})
			.catch(err => err)
		this.list = data.list
		this.pageConfig.total = data.page.total
	}

	async emptyOutbound(outboundCode: string) {
		const isConfirm = await this.$confirm('确认清空出库单？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (isConfirm) {
			await this.$axios
				.post(`/v1/jobline/warehouse-record/truncate/${outboundCode}`)
				.then(() => this.search(false))
				.catch(() => false)
		}
	}

	async getExpressCompany() {
		const { data } = await this.$axios.get('v1/jobline/express-company', {})
		this.expressCompany = data
	}

	openDockDialg({ row, $index }: any) {
		this.dockDialog.visible = true
		this.dockDialog.form.code = row.code
		this.dockDialog.form.stockout_num = row.dock_num
		this.dockDialog.form.stockout_company = row.dock_company
		this.dockDialog.form.outbound_time = row.operated
			? formatDate('yyyy-MM-dd h:i', row.operated)
			: ''
	}

	async saveData() {
		const isValidate = await this.$refs['dock-form']
			.validate()
			.catch(() => false)
		if (!isValidate) return
		await this.$axios
			.post('v1/jobline/warehouse-record/batch', {
				warehouse_record_datas: [
					{
						code: this.dockDialog.form.code,
						dock_company: this.dockDialog.form.stockout_company,
						dock_num: this.dockDialog.form.stockout_num,
						outbound_time: this.dockDialog.form.outbound_time
					}
				]
			})
			.then(() => {
				this.$message.success('回填成功')
				this.search(false)
			})
			.finally(() => (this.dockDialog.visible = false))
	}

	formatExpressCompany(code: string) {
		if (!code) {
			return '-'
		}
		this.expressCompany = this.expressCompany || this.getExpressCompany()
		const express: any = this.expressCompany.find(
			(item: any) => item.value == code
		)
		return express?.title || '-'
	}
}
