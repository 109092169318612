var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("出库记录")]),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { attrs: { gutter: 30, span: 24 } }, [
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-form",
                {
                  ref: "search-form",
                  staticClass: "search-form",
                  attrs: { inline: true, model: _vm.searchForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "出库时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.searchForm.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "date", $$v)
                          },
                          expression: "searchForm.date",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出库单号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "出库单号", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search(true)
                          },
                        },
                        model: {
                          value: _vm.searchForm.outboundCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "outboundCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchForm.outboundCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "z-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.search(true)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm.isWechat
                        ? _c(
                            "z-button",
                            {
                              attrs: { type: "default" },
                              on: { click: _vm.scanBarcode },
                            },
                            [_vm._v("扫码")]
                          )
                        : _vm._e(),
                      _c("z-button", { on: { click: _vm.resetSearch } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ctrl-header" },
            [
              _c(
                "el-link",
                {
                  attrs: {
                    underline: false,
                    href: "/warehouse/stock-out/scan",
                  },
                },
                [
                  _c(
                    "z-button",
                    { attrs: { type: "primary", size: "medium", plain: "" } },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-icon_btn_addbox" },
                          }),
                        ]
                      ),
                      _vm._v("创建出库"),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-table",
                { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "code", label: "单号", "min-width": "210" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  underline: false,
                                  href: `/warehouse/stock-out/scan?outbound_code=${scope.row.code}`,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.code))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "operated",
                      label: "出库时间",
                      "min-width": "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(scope.row.operated)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "package_attr_total",
                      label: "包裹数量",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dock_company",
                      label: "运输商",
                      "min-width": "280",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatExpressCompany(
                                    scope.row.dock_company
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dock_num",
                      label: "出仓单号",
                      "min-width": "280",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.dock_num || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status === 1
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "mini", type: "success" } },
                                  [_vm._v(_vm._s(scope.row.status_text))]
                                )
                              : _c(
                                  "el-tag",
                                  { attrs: { size: "mini", type: "primary" } },
                                  [_vm._v(_vm._s(scope.row.status_text))]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "created",
                      label: "创建时间",
                      "min-width": "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(scope.row.created)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      "min-width": "230",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 2 ||
                            _vm.now - scope.row.operated < 86400 * 3
                              ? _c(
                                  "z-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDockDialg(scope)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-edit" }),
                                    _vm._v("回填单号"),
                                  ]
                                )
                              : _vm._e(),
                            scope.row.status == 2
                              ? _c(
                                  "z-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      size: "mini",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.emptyOutbound(scope.row.code)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "icon",
                                        attrs: { "aria-hidden": "true" },
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              "#icon-icon_btn_deletable",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v("清空"),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("z-pagination", {
                attrs: {
                  total: _vm.pageConfig.total,
                  "page-size": _vm.pageConfig.size,
                  "current-page": _vm.pageConfig.current_page,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageConfig, "current_page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageConfig, "current_page", $event)
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "480px",
            visible: _vm.dockDialog.visible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dockDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("回填单号")]
          ),
          _c(
            "el-form",
            {
              ref: "dock-form",
              staticClass: "info-form",
              attrs: {
                model: _vm.dockDialog.form,
                rules: _vm.validateDockInfo,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "出库单号", prop: "code" },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.dockDialog.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.dockDialog.form, "code", $$v)
                      },
                      expression: "dockDialog.form.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "运输商", prop: "stockout_company" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "", placeholder: "请选择运输商" },
                      model: {
                        value: _vm.dockDialog.form.stockout_company,
                        callback: function ($$v) {
                          _vm.$set(_vm.dockDialog.form, "stockout_company", $$v)
                        },
                        expression: "dockDialog.form.stockout_company",
                      },
                    },
                    _vm._l(_vm.expressCompany, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.value,
                          attrs: { label: item.title, value: item.value },
                        },
                        [
                          _vm._v(
                            " [" +
                              _vm._s(item.label) +
                              "]" +
                              _vm._s(item.title) +
                              " "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "出仓单号", prop: "stockout_num" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入出仓单号" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.saveData.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.dockDialog.form.stockout_num,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dockDialog.form,
                          "stockout_num",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "dockDialog.form.stockout_num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input outbound-time",
                  attrs: {
                    label: "出库时间",
                    prop: "outbound_time",
                    required: "",
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm",
                      "value-format": "yyyy-MM-dd HH:mm",
                      type: "datetime",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.dockDialog.form.outbound_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.dockDialog.form, "outbound_time", $$v)
                      },
                      expression: "dockDialog.form.outbound_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "z-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveData } },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }